import React from "react";
import { Container } from "reactstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

import "./styles.scss";
import portfolio from "./portfolio.json";

const Portfolio = () => {

    interface IPortfolioItem {
        fileName: string;
        title: string;
        hoverText: string;
    };

    const mapImage = (item: IPortfolioItem) => {
        const { fileName, title, hoverText } = item;
        const imagePath = "images/portfolio/";
        const thumbnail = imagePath + "p/" + fileName;
        const photo = imagePath + "optimized/" + fileName;

        return {
            original: photo,
            thumbnail,
            originalAlt: title,
            thumbnailAlt: title,
            originalTitle: title,
            thumbnailTitle: title,
            thumbnailLabel: title,
            description: hoverText
        };
    };

    const images = portfolio.map(mapImage);

    return (
        <div id="portfolio">
            <Container>
                <div className="section-title">
                    <h1>Nossos Produtos</h1>
                </div>
                <ImageGallery
                    items={images}
                    lazyLoad={true}
                    slideInterval={5000}
                    slideOnThumbnailOver
                />
            </Container>
        </div>
    );
};

export default Portfolio;