import React from "react";
import "typeface-roboto";

import { Menu, Payment, Header, GetTouch, About, Portfolio, Testimonials, Contact, Footer } from "./sections";

function App() {
  return (
    <React.Fragment>
      <Menu />
      <Header />
      <GetTouch />
      <Portfolio />
      <About />
      <Payment />
      <Testimonials />
      <Contact />
      <Footer />
    </React.Fragment>
  );
}

export default App;
