import React from "react";
import { Col, Container, Row } from "reactstrap";
import { ScrollTo } from "../../utils/scroll";

const GetTouch = () => {
    return (
        <div id="get-touch">
            <Container className="text-center">
                <Row>
                    <Col sm="12">
                        <h1>Produtos sob medida</h1>
                        <p>Entre em contato conosco hoje mesmo e solicite seu orçamento.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <ScrollTo componentClass="button" component="button" targetId="contact" className="btn btn-custom btn-lg">Solicitar Orçamento</ScrollTo>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default GetTouch;