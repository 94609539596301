import React from "react";
import { ScrollTo } from "../../utils/scroll";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const Header = () => {

    const useStyles = makeStyles(theme => ({
        introText: {
            paddingTop: "350px",
            paddingBottom: "150px",
            textAlign: "center",
            [theme.breakpoints.down("md")]: {
                paddingTop: "90px",
                paddingBottom: "40px",
                textAlign: "center"
            }
        }
    }));

    const classes = useStyles();

    return (
        <header id="header">
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className={classNames("col-sm-12", classes.introText)}>
                                <h1>Qualidade, durabilidade, higiene e beleza</h1>
                                <p>Tenha em sua casa, empresa ou negócio, ambientes modernos, usando móveis e
                                    peças feitas em aço inox de alta qualidade.</p>
                                <ScrollTo componentClass="button" targetId="about" className="btn btn-custom btn-lg">Ler Mais</ScrollTo>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;