import GooglePlacesIcon from "@material-ui/icons/Place";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { Button, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";

export interface IFormData {
    nome: string;
    email: string;
    empresa: string;
    telefone: string;
    mensagem: string;
    assunto: string;
};

const MAIL_RESPONSES = {
    SUCCESS: "success",
    ERROR: "error"
};

const Contact = () => {

    const [blockingUi, setBlockingUi] = useState(false);
    const [mailResponse, setMailResponse] = useState("");
    const [captchaSuccess, setCaptchaSuccess] = useState(false);
    const recapchaSiteKey = "6Lf6ctwUAAAAAP_B9LFI6SF9k-RGOeQDw_TSySnm";

    useEffect(() => {
        loadReCaptcha(recapchaSiteKey);
    }, []);

    const verifyCaptchaCallback = (recaptchaToken: any) => {
        console.log("verifyCaptchaCallback, token=", recaptchaToken);
        axios({
            method: "post",
            url: "https://qualinoski.com.br/api/google-recaptcha-v3/index.php",
            headers: { "content-type": "application/json" },
            data: {
                token: recaptchaToken,
                action: "contact"
            }
        }).then(resp => {
            console.log("resp=", resp);
            const status = resp.data.status;
            console.log("Captcha verification=", status);

            if (status === "success") {
                setCaptchaSuccess(true);
            }
        }).catch(error => {
            console.log("Captcha error=", error);
        });
    };

    const sendMail = async (formData: IFormData) => {
        return axios({
            method: "post",
            url: "https://qualinoski.com.br/api/contact/index.php",
            headers: { "content-type": "application/json" },
            data: formData
        });
    };

    const { control, handleSubmit, errors } = useForm();

    const onSubmit = (data: any) => {
        if (!captchaSuccess) {
            setMailResponse("Captcha não verificado!");
        } else {
            setBlockingUi(true);
            console.log("Enviando e-mail... FormData=", data);
            sendMail(data)
                .then(resp => {
                    if (resp.data.status === MAIL_RESPONSES.SUCCESS) {
                        console.log("E-mail enviado. MailResponse=", resp);
                        setMailResponse(MAIL_RESPONSES.SUCCESS);
                    } else {
                        console.log("Erro. Response=", resp);
                        setMailResponse(MAIL_RESPONSES.ERROR);
                    }
                    setBlockingUi(false);
                })
                .catch(error => {
                    setBlockingUi(false);
                    console.log("Ocorreu um erro ao enviar o e-mail. Mail Response=", error);
                    setMailResponse(MAIL_RESPONSES.ERROR);
                });
        }
    };

    const socialLinks = [{
        name: "Google Places",
        url: "https://g.page/Qualinoski?we",
        icon: GooglePlacesIcon
    }];

    return (
        <React.Fragment>
            <BlockUi
                tag="div"
                blocking={blockingUi}
                message="Enviando e-mail, aguarde..."
            >

                <div id="contact">
                    <Container>
                        <Row>
                            <Col sm="8">
                                <div className="section-title">
                                    <h1>Entre em contato</h1>
                                    <p>Preencha o formulário abaixo para nos enviar um e-mail.<br />
                                        Nós iremos responder o mais breve possível!</p>
                                </div>
                                {mailResponse === MAIL_RESPONSES.SUCCESS && (
                                    <Alert severity="success">E-mail enviado com sucesso!</Alert>
                                )}
                                {mailResponse === MAIL_RESPONSES.ERROR && (
                                    <Alert severity="error">Ocorreu um erro ao enviar o e-mail!</Alert>
                                )}
                                <Form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Controller
                                                    as={<Input />}
                                                    name="nome"
                                                    control={control}
                                                    placeholder="Nome"
                                                    required
                                                    defaultValue=""
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Controller
                                                    as={<Input />}
                                                    name="email"
                                                    type="email"
                                                    control={control}
                                                    placeholder="E-mail"
                                                    required
                                                    defaultValue=""
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Controller
                                                    as={<Input />}
                                                    name="empresa"
                                                    control={control}
                                                    placeholder="Empresa"
                                                    defaultValue=""
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Controller
                                                    as={<Input />}
                                                    mask="(99) 9 9999-9999"
                                                    maskChar="_"
                                                    tag={InputMask}
                                                    required
                                                    name="telefone"
                                                    type="tel"
                                                    control={control}
                                                    defaultValue=""
                                                    placeholder="Telefone"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Controller
                                                    as={<Input />}
                                                    name="assunto"
                                                    control={control}
                                                    placeholder="Assunto"
                                                    defaultValue=""
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Controller
                                                    as={<Input />}
                                                    type="textarea"
                                                    name="mensagem"
                                                    id="mensagem"
                                                    placeholder="Escreva aqui a sua mensagem"
                                                    rows={6}
                                                    invalid={errors["mensagem"] !== undefined}
                                                    control={control}
                                                    required
                                                    defaultValue=""
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <Button title={!captchaSuccess ? "Erro na verificação do captcha" : "Enviar"} disabled={!captchaSuccess}>Enviar</Button>
                                        </Col>
                                        <Col sm="6" className="text-right">
                                            <ReCaptcha
                                                sitekey={recapchaSiteKey}
                                                action="contact"
                                                verifyCallback={verifyCaptchaCallback}
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col sm={{ size: "3", offset: 1 }} className="contact-info">
                                <div className="contact-item">
                                    <h4>Dados de Contato</h4>
                                    <p>
                                        <span>Endereço</span>
                                        Rua Padre Paulo Canelles, 977<br />
                                        Santa Cândida - Curitiba - PR<br />
                                        CEP 82.720-350
                                    </p>
                                </div>
                                <div className="contact-item">
                                    <p>
                                        <span>Telefones</span>
                                        +55 (41)   3257-0001<br />
                                        +55 (41) 9 9767-7768<br />
                                    </p>
                                </div>
                                <div className="contact-item">
                                    <p>
                                        <span>E-mail</span>
                                        contato@qualinoski.com.br
                                    </p>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm="12">
                                <div className="social">
                                    <ul>
                                        {socialLinks.map((socialLink, i) => (
                                            <li key={i}>
                                                <a
                                                    href={socialLink.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title={socialLink.name}>
                                                    <socialLink.icon
                                                        style={
                                                            {
                                                                fontSize: 40,
                                                                color: "#fff"
                                                            }
                                                        }
                                                    />
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </BlockUi>
        </React.Fragment>
    );
};

export default Contact;