import React from "react";
import { Col, Container, Row } from "reactstrap";
import faixada from "../../images/fotos/faixada.jpg";


const About = () => {
    return (
        <div id="about">
            <Container>
                <Row>
                    <Col xs="12" sm="6">
                        <img src={faixada} className="img-fluid" alt="Sobre Nós" />
                    </Col>
                    <Col xs="12" sm="6">
                        <div className="about-text">
                            <h1>Sobre A Qualinoski</h1>
                            <p>Nossa empresa nasceu em 2010. Somos especializados na fabricação de móveis sob medida em aço inox.</p>
                            <p>Atendemos hotéis, hospitais, indústrias, restaurantes, fast food, bares, lanchonetes, panificadoras, empresas que possuem cozinhas profissionais, dentre outros.</p>
                            <p>Trabalhamos com aço inox da melhor qualidade do mercado o que garante produtos com máxima durabilidade.</p>
                            <p>Nosso trabalho é pautado pela satisfação de nossos clientes.</p>
                            <h2>Porque nos escolher?</h2>
                            <Row className="list-style">
                                <Col xs="12" sm="6">
                                    <ul>
                                        <li>Mais de 10 anos de experiência no mercado</li>
                                        <li>Qualidade aprovada por mais de 200 clientes</li>
                                        <li>Ótimo custo/benefício</li>
                                    </ul>
                                </Col>
                                <Col xs="12" sm="6">
                                    <ul>
                                        <li>Orçamento sem compromisso</li>
                                        <li>Entrega e instalação feitas pelo próprio fabricante</li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default About;