import React, { useState, useRef } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import DehazeIcon from '@material-ui/icons/Dehaze';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import classNames from "classnames";

import logo from "../../images/qualinoski-logo.png";
import { scrollToRef } from "../../utils/scroll";

const useStyles = makeStyles(theme => ({
    navbar: {
        display: "inline-flex",
        backgroundColor: "#d1d2d3",
        opacity: .9
    },
    navbarLogo: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
        width: "15%",
        cursor: "pointer"
    },
    navbarToggler: {
        "&:active, &:focus": {
            border: "none",
            outline: 0,
            fontWeight: "normal"
        },
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    whatsAppLogo: {
        color: "green"
    },
    whatsapp: {
        position: "absolute",
        [theme.breakpoints.up("xs")]: {
            right: 10
        },
        [theme.breakpoints.up("md")]: {
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "200px"
        },
    },
    navbarBrand: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    }
}));

const Menu = () => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const classes = useStyles();
    const topRef = useRef(null);
    const whatsappLink = "https://api.whatsapp.com/send?phone=5541997677768";

    return (
        <Navbar className={classes.navbar} default fixed="top" expand="md">
            <div style={{ display: "none" }} ref={topRef}></div>
            <NavbarToggler onClick={toggle} className={classes.navbarToggler}>
                <DehazeIcon fontSize="large" />
            </NavbarToggler>
            <NavbarBrand className={classes.navbarBrand} onClick={() => {
                scrollToRef(topRef);
                if (isOpen) {
                    toggle();
                }
            }}>
                <img src={logo} alt="Logomarca" className={classes.navbarLogo} />
            </NavbarBrand>
            <div className={classNames(classes.whatsapp, "text-center")}>
                <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                    <WhatsAppIcon fontSize="large" className={classes.whatsAppLogo} />
                    <span> (41) 9 9767-7768</span>
                </a>
            </div>
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                    <NavItem><NavLink href="#portfolio" className="nav-link">Produtos</NavLink></NavItem>
                    <NavItem><NavLink href="#about" className="nav-link">Sobre</NavLink></NavItem>
                    <NavItem><NavLink href="#payment" className="nav-link">Pagamento</NavLink></NavItem>
                    <NavItem><NavLink href="#testimonials" className="nav-link">Clientes</NavLink></NavItem>
                    <NavItem><NavLink href="#contact" className="nav-link">Contato</NavLink></NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default Menu;