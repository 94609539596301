import React from "react";
import { Col, Container, Row } from "reactstrap";
import testimonials from "./testimonials.json";


const Testimonials = () => {

    const renderTestimonial = (testimonial: any, index: number) => {
        const { fileName, clientName } = testimonial;
        const imagePath = "images/clients/";
        const img = imagePath + fileName;

        return (
            <Col xs="12" sm="12" md="4" lg="4" key={index}>
                <div className="testimonial">
                    <img src={img} alt={clientName} />
                </div>
            </Col>
        );
    };

    return (
        <div id="testimonials">
            <Container>
                <div className="section-title">
                    <h1>Clientes</h1>
                </div>
                <Row>
                    {testimonials.map(renderTestimonial)}
                </Row>
            </Container>
        </div>
    );
};

export default Testimonials;