import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ReceiptIcon from "@material-ui/icons/Receipt";
import React, { ReactElement } from "react";
import { Col, Container, Row } from "reactstrap";
import amexImg from "./images/american-express@2x.png";
import cabalImg from "./images/cabal.svg";
import eloImg from "./images/elo_2@2x.png";
import hiperImg from "./images/hipercard@2x.png";
import masterImg from "./images/mastercard_2@2x.png";
import visaImg from "./images/visa@2x.png";

const Payment = () => {
  interface IPaymentType {
    title: string;
    subTitle: string;
    description: string;
    icon: ReactElement;
    jsxContent?: ReactElement;
  }

  const useStyles = makeStyles(theme => ({
    container: {
      paddingTop: "80px"
    },
    card: {
      minHeight: "300px"
    },
    cardFlag: {
      minWidth: "100%",
      maxWidth: "100%",
      paddingBottom: "10%"
    }
  }));

  const classes = useStyles();

  const renderPaymentCards = (paymentType: IPaymentType, index: number) => {
    const { title, subTitle, description, icon, jsxContent } = paymentType;
    return (
      <Col sm="3" key={index}>
        <Card className={classes.card}>
          <CardHeader avatar={icon} title={title} subheader={subTitle} />
          <CardContent className="text-justify">
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
            <br />
            {jsxContent}
          </CardContent>
        </Card>
      </Col>
    );
  };

  interface ICardInfo {
    name: string;
    imageSrc: string;
  }

  const renderAcceptedCard = (cardInfo: ICardInfo, index: number) => {
    const { name, imageSrc } = cardInfo;
    return (
      <Col xs="6" sm="6" md="6" lg="4" key={index}>
        <img className={classes.cardFlag} src={imageSrc} alt={name} />
      </Col>
    );
  };

  const acceptedCards: Array<ICardInfo> = [
    {
      name: "Visa",
      imageSrc: visaImg
    },
    {
      name: "Mastercard",
      imageSrc: masterImg
    },
    {
      name: "American Express",
      imageSrc: amexImg
    },
    {
      name: "Elo",
      imageSrc: eloImg
    },
    {
      name: "Hipercard",
      imageSrc: hiperImg
    },
    {
      name: "Cabal",
      imageSrc: cabalImg
    }
  ];

  const cardIconFontSize = "large";

  const paymentTypes: Array<IPaymentType> = [
    {
      title: "À vista",
      subTitle: "3% de desconto",
      description:
        "Desconto aplicado para pagamento integral na confirmação do pedido",
      icon: <AttachMoneyIcon fontSize={cardIconFontSize} />
    },
    {
      title: "Parcelado",
      subTitle: "Boleto Bancário",
      description:
        "40% de entrada + 30, 60 e 90 dias, para compras acima de R$ 1.500,00",
      icon: <ReceiptIcon fontSize={cardIconFontSize} />
    },
    {
      title: "Parcelado",
      subTitle: "Cartão de Crédito",
      description:
        "Até 12 parceladas sem juros, para compras acima de R$ 1.500,00",
      icon: <CreditCardIcon fontSize={cardIconFontSize} />,
      jsxContent: <Row>{acceptedCards.map(renderAcceptedCard)}</Row>
    },
    {
      title: "Parcelado",
      subTitle: "Paypal",
      description:
        "Até 10 parceladas sem juros, para compras acima de R$ 1.500,00",
      icon: <FontAwesomeIcon size="2x" icon={faPaypal} />
    }
  ];

  return (
    <Container id="payment" className={classes.container}>
      <div className="section-title">
        <h1>Formas de Pagamentos Aceitas</h1>
      </div>
      <Row>{paymentTypes.map(renderPaymentCards)}</Row>
    </Container>
  );
};

export default Payment;
