import React from "react";

export const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

export const scrollToId = (id: string) => {
    const el: HTMLElement | null = document.getElementById(id)
    if (el !== null) {
        window.scrollTo(0, el.offsetTop)
    }
};

export interface IScrollToProps {
    children: any;
    targetRef?: React.RefObject<any>;
    targetId?: string;
    [anyOtherProps: string]: any;
}

export function ScrollTo(props: IScrollToProps) {
    const { componentClass: Component, targetRef, children, targetId, ...rest } = props;

    const handleClick = () => {
        if (targetRef) {
            scrollToRef(targetRef);
        } else if (targetId && targetId !== "") {
            scrollToId(targetId);
        } else {
            throw new Error("targetRef e targetId não informados");
        }
    };

    return (
        <Component onClick={handleClick} {...rest}>{children}</Component>
    );
}
