import React from "react";
import { Container, Col, Row } from "reactstrap";

const Footer = () => {
    return (
        <div id="footer">
            <Container fluid>
                <Row>
                    <Col xs="12" sm="6" className="text-left">
                        <p>&copy;2020 QUALINOSKI&reg;.</p>
                    </Col>
                    <Col xs="12" sm="6" className="text-right">
                        <p>Construído por <a href="mailto:rglodzinski@gmail.com" rel="nofollow">Ricardo Glodzinski</a></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;